<template>
  <CCard no-header>
    <CCardBody>
      <h3><CIcon name="cil-pencil" /> {{ operationName }} Tax Class</h3>
      <CAlert v-if="message" :color="alertType"> {{ message }} </CAlert>
      <CRow>
        <CCol col="10"></CCol>
        <CCol class="text-right">
          <div><label>Active</label></div>
          <CSwitch
            color="success"
            size="lg"
            :checked.sync="taxClass.active"
          ></CSwitch>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput label="Name" type="text" v-model="taxClass.name"></CInput
        ></CCol>
        <CCol>
          <CInput
            textarea="true"
            label="Amount"
            :rows="9"
            v-model="taxClass.amount"
          ></CInput>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="6"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton color="secondary" class="btn-lg" @click="goBack"
            ><CIcon name="cil-list" /> Back to the list</CButton
          >

          <CButton
            color="primary"
            @click="save()"
            class="btn-lg"
            style="margin-left: 15px"
            ><CIcon name="cil-save" /> Save</CButton
          >
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
export default {
  name: "AddNewTaxClass",
  data: () => {
    return {
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      token: localStorage.getItem("api_token"),

      // Alert
      alertType: "danger",
      message: null,

      taxClass: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        amount: null,
        active: true,
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/TaxClass/${id}`)
        .then(function (response) {
          self.taxClass = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    save() {
      let self = this;
      var regExDecimal = /^-{0,1}\d*\.{0,1}\d+$/;
      if (
        !self.taxClass.name ||
        !self.taxClass.amount ||
        !regExDecimal.test(self.taxClass.amount)
      ) {
        self.failed = true;
        self.message = "Please enter all needed fields.";
        self.loading = false;
                  self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
        return;
      }

      axios
        .post(`${this.$apiAdress}/v1/TaxClass`, self.taxClass)
        .then((response) => {
          self.taxClass.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created tax class.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
  },
  mounted: function () {
    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.get(this.$route.params.id);
    }
  },
};
</script>
